import {
  Dataset,
  DistributionMethod,
  MandatesPerParty,
  VotesPerParty,
} from '../types';
import { maxKey, objectFilterMap } from '../utils';

export abstract class AbstractDivisor implements DistributionMethod {
  abstract getName(): string;
  abstract getDescription(): string;

  protected abstract getDivisor(round: number, mandates: number): number;

  getMandateDistribution(
    dataset: Dataset,
    mandates: number,
  ): MandatesPerParty {
    const mandatesPerParty: MandatesPerParty = {};
    let round: number = 0;

    while (round < mandates) {
        let pointsPerParty: VotesPerParty = objectFilterMap(
          dataset.votesPerParty,
          (votes, party, skip) => dataset.partiesAboveQuorum.includes(party)
            ? votes / this.getDivisor(round, mandatesPerParty[party] || 0)
            : skip(),
        );

        const winner = maxKey(pointsPerParty);
        winner in mandatesPerParty || (mandatesPerParty[winner] = 0);
        ++mandatesPerParty[winner];

      ++round;
    }

    return mandatesPerParty;
  }
}
