import { DistributionMethodMap } from '../types';
import { DHondt } from './dhondt';
import { Proportional } from './proportional';
import { SainteLague } from './sainteLague';
import { HagenbachBischoff } from './hagenbachBischoff';

export const methods: DistributionMethodMap = {
  dhondt: new DHondt(),
  sainteLague: new SainteLague(),
  hagenbachBischoff: new HagenbachBischoff(),
  proportional: new Proportional(),
};
