import React from 'react';
import './App.css';
import { Map } from './Map';
import { ResultsProvider } from './ResultsProvider';
import { SelectDataset, SelectLevel, SelectMethod, SettingsProvider } from './SettingsProvider';
import { Stats } from './Stats';
import { Info } from './Info';


function App() {
  return (
    <SettingsProvider>
      <ResultsProvider>
        <header>
          <h1>Model přepočtu hlasů na mandáty v parlamentních volbách</h1>
        </header>
        <main>
          <div className="map">
            <Map />
          </div>
          <div className="settings">
            <p>Metoda přepočtu: <SelectMethod /></p>
            <p>Volební obvody: <SelectLevel /></p>
            <p>Data: <SelectDataset /></p>
            <Stats />
          </div>
        </main>
        <footer>
          <Info />
        </footer>
      </ResultsProvider>
    </SettingsProvider>
  );
}

export default App;
