import React from 'react';
import { methods } from './model';

export const Info: React.FC = () => (
  <>
    <h3>Metodika a další informace</h3>
    <p>
      Zdrojem podkladových dat pro výpočty je web <a href="https://volby.cz" target="_blank">volby.cz</a>.
      Každá datová sada je tříděna po jednotlivých krajích a dále po kandidujících stranách. Datové
      sady obsahují počty hlasů pro všechny strany, které v daných volbách kandidovaly, tedy i ty,
      které nepřesáhly 5% hranici v celorepublikovém součtu; tyto strany nicméně v grafech a tabulkách
      pro přehlednost uvedené nejsou. Nadřazené volební obvody (regiony, celá ČR) jsou odvozeny
      prostým sečtením příslušných krajů.
    </p>
    <p>Metody přepočtu jsou implementovány dle následujících definic:</p>
    <ul>
      {Object.entries(methods).map(([id, method]) => (
        <li key={id}>
          {method.getUrl ? (
            <a href={method.getUrl()} target="_blank">
              <strong>{method.getName()}</strong>
            </a>
          ) : (
            <strong>{method.getName()}</strong>
          )}: {method.getDescription()}
        </li>
      ))}
    </ul>
  </>
);
