import {
  Dataset,
  DistributionMethod,
  MandatesPerParty,
} from '../types';

export class Proportional implements DistributionMethod {
  getName(): string {
    return '% hlasů = % mandátů';
  }

  getDescription(): string {
    return `
      Tato metoda má pouze ilustrační účel, v praxi není možné ji použít. Metoda přepočítává
      hlasy získané v daném volebním obvodu na mandáty přímým poměrem bez zaokrouhlování;
      počty mandátů tak zpravidla nevychází celočíselné, což je v praxi těžko proveditelné.
      Takovéto rozložení mandátů v daném volebním obvodu by nicméně bylo objektivně nejvíc férové
      z hlediska počtu hlasů potřebných pro získání jednoho mandátu.
    `;
  }

  getMandateDistribution(
    dataset: Dataset,
    mandates: number,
  ): MandatesPerParty {
    const mandatesPerParty: MandatesPerParty = {};

    for (const party of dataset.partiesAboveQuorum) {
      mandatesPerParty[party] = dataset.votesPerParty[party] / dataset.totalVotesAboveQuorum;
    }

    return mandatesPerParty;
  }
}
