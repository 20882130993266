import { AbstractDivisor } from './abstractDivisor';

export class DHondt extends AbstractDivisor {
  getName(): string {
    return "d'Hondtova metoda";
  }

  getDescription(): string {
    return `
      Mandáty se přidělují vícekolově, vždy jeden mandát v každém kole. V každém kole se
      pro každou stranu určí kolový počet hlasů a ta strana, která má kolový počet hlasů
      nejvyšší, kolo vyhrává a získává mandát toho kola. Kolový počet hlasů se určí jako
      podíl získaných hlasů strany ku počtu mandátů, které získala v předchozích kolech,
      zvýšeným o jedničku (=počet hlasů / (mezipočet mandátů + 1)).
    `;
  }

  getUrl() : string {
    return 'https://cs.wikipedia.org/wiki/D%27Hondtova_metoda';
  }

  protected getDivisor(round: number, mandates: number): number {
    return mandates + 1;
  }
}
