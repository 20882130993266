import React, { createContext, useContext } from 'react';
import { Model, Results } from './model';
import { useMemoizedFactory } from './utils/memoizedFactory';
import { AsyncComponent } from './async';
import { useSettings } from './SettingsProvider';

const ResultsContext = createContext<Results | undefined>(undefined);

export function useResults() : Results | undefined {
  return useContext(ResultsContext);
}

export const ResultsProvider: React.FC = ({
  children,
}) => {
  const { datasetUrl, method, level } = useSettings();
  const model = useMemoizedFactory(createModel, []);

  return (
    <AsyncComponent factory={() => loadResults(model, datasetUrl, method, level)}>
      {results => (
        <ResultsContext.Provider value={results}>
          {children}
        </ResultsContext.Provider>
      )}
    </AsyncComponent>
  )
};

function createModel() : Model {
  return new Model('/datasets/ps-2017.json', 'dhondt', 2);
}

function loadResults(model: Model, datasetUrl: string, method: string, level: number) : Promise<Results> {
  model.setDatasetUrl(datasetUrl);
  model.setMethod(method);
  model.setLevel(level);
  return model.getResults();
}
