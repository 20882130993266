import React, { CSSProperties, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { DataEntry } from 'react-minimal-pie-chart/types/commonTypes';
import { useSettings } from './SettingsProvider';
import { useResults } from './ResultsProvider';
import { districts, MandatesPerParty, parties, regions, Results } from './model';
import { useMemoizedFactory } from './utils/memoizedFactory';

import './Map.css';
import { strcmp } from './utils';

export type MapProps = {
  children?: never;
};

export const Map: React.FC<MapProps> = () => {
  const settings = useSettings();
  const results = useResults();
  const charts = useMemoizedFactory(resultsToCharts, [settings.level, results]);
  const [label, setLabel] = useState<LabelProps>();

  return (
    <div id="map" className={`level-${settings.level}`}>
      {charts.map(chart => (
        <div
          key={chart.id}
          className="district-chart"
          style={chart.style}>
          <PieChart
            data={chart.data}
            onMouseOver={(evt, i) => setLabel(getLabelProps(chart, i))}
            onMouseOut={() => setLabel(undefined)}
            startAngle={270}
            segmentsShift={2}
            radius={48} />
          {label && label.chart === chart.id && <Label key="label" {...label} />}
        </div>
      ))}
    </div>
  )
};

const Label: React.FC<LabelProps> = ({
  badge,
  text,
}) => {
  return (
    <div className="district-chart-label">
      <span className="district-chart-label-badge" style={{ background: badge }} />
      {text}
    </div>
  )
}

type ChartProps = {
  id: string;
  data: DataEntry[];
  style: CSSProperties;
}

type LabelProps = {
  chart: string;
  badge: string;
  text: string;
};

function resultsToCharts(level: number, results?: Results): ChartProps[] {
  if (!results) {
    return [];
  }

  const global: ChartProps = {
    id: 'cz',
    style: {
      left: '42.24%',
      top: '46.32%',
      width: '40%',
    },
    data: mandatesPerPartyToChartDataPoints(results.mandatesPerParty),
  };

  if (!results.mandatesPerUnitAndParty) {
    return [global];
  }

  global.style = {
    left: '90%',
    top: '20%',
    width: '18%',
  };

  const map = level === 1 ? regions : districts;

  return Object.entries(results.mandatesPerUnitAndParty)
    .map(([unit, mandatesPerParty]) => ({
      id: unit,
      style: map[unit].chartStyles,
      data: mandatesPerPartyToChartDataPoints(mandatesPerParty),
    })).concat(global);
}

function mandatesPerPartyToChartDataPoints(mandatesPerParty: MandatesPerParty): DataEntry[] {
  return Object.entries(mandatesPerParty)
    .map(([party, mandates]) => ({
      key: party,
      value: mandates,
      ...parties[party],
    }))
    .sort((a, b) => strcmp(a.name, b.name));
}

function getLabelProps(chart: ChartProps, idx: number) : LabelProps {
  return {
    chart: chart.id,
    badge: chart.data[idx].color,
    text: `${chart.data[idx].name}: ${chart.data[idx].value.toString().replace(/(\.\d\d)\d+/, '$1')}`,
  };
}
