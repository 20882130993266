import {
  Datasets,
  Results,
  VotesPerUnitAndParty,
} from './types';
import {
  computeResults,
  expandDataset,
} from './utils';
import { methods } from './methods';

export class Model {
  private datasetUrl: string;
  private method: string;
  private level: number;
  private readonly datasets: Record<string, Datasets>;
  private readonly results: Record<string, Results>;

  constructor(
    datasetUrl: string,
    method: string,
    level: number,
  ) {
    this.datasetUrl = datasetUrl;
    this.method = method;
    this.level = level;
    this.datasets = {};
    this.results = {};
  }

  setDatasetUrl(url: string): void {
    this.datasetUrl = url;
  }

  setMethod(method: string): void {
    this.method = method;
  }

  setLevel(level: number): void {
    this.level = level;
  }

  async getResults(): Promise<Results> {
    const key = `${this.datasetUrl}|${this.method}|${this.level}`;

    if (this.results[key]) {
      return this.results[key];
    }

    const datasets = await this.getDatasets();
    return this.results[key] = computeResults(datasets, methods[this.method], this.level);
  }

  private async getDatasets(): Promise<Datasets> {
    if (this.datasets[this.datasetUrl]) {
      return this.datasets[this.datasetUrl];
    }

    const response = await fetch(this.datasetUrl);
    const votesPerDistrictAndParty: VotesPerUnitAndParty = await response.json();
    return this.datasets[this.datasetUrl] = expandDataset(votesPerDistrictAndParty);
  }
}
