import { Districts, MandatesPerUnit, Parties, Regions } from './types';

export const districts: Districts = {
  'cz010': {
    name: 'Hlavní město Praha',
    chartStyles: {
      left: '35.23%',
      top: '41.16%',
      width: '9%',
    },
  },
  'cz020': {
    name: 'Středočeský kraj',
    chartStyles: {
      left: '42.24%',
      top: '46.32%',
    },
  },
  'cz031': {
    name: 'Jihočeský kraj',
    chartStyles: {
      left: '33.54%',
      top: '75.48%',
    },
  },
  'cz032': {
    name: 'Plzeňský kraj',
    chartStyles: {
      left: '16.15%',
      top: '55.99%',
    },
  },
  'cz041': {
    name: 'Karlovarský kraj',
    chartStyles: {
      left: '9.97%',
      top: '37.16%',
    },
  },
  'cz042': {
    name: 'Ústecký kraj',
    chartStyles: {
      left: '25.17%',
      top: '25.55%',
    },
  },
  'cz051': {
    name: 'Liberecký kraj',
    chartStyles: {
      left: '44.35%',
      top: '16.26%',
    },
  },
  'cz052': {
    name: 'Královéhradecký kraj',
    chartStyles: {
      left: '55.73%',
      top: '29.67%',
    },
  },
  'cz053': {
    name: 'Pardubický kraj',
    chartStyles: {
      left: '59.96%',
      top: '47.61%',
    },
  },
  'cz063': {
    name: 'Kraj Vysočina',
    chartStyles: {
      left: '52.56%',
      top: '64.12%',
    },
  },
  'cz064': {
    name: 'Jihomoravský kraj',
    chartStyles: {
      left: '67.84%',
      top: '76.90%',
    },
  },
  'cz071': {
    name: 'Olomoucký kraj',
    chartStyles: {
      left: '76.13%',
      top: '57.67%',
    },
  },
  'cz072': {
    name: 'Zlínský kraj',
    chartStyles: {
      left: '83.53%',
      top: '72.51%',
    },
  },
  'cz080': {
    name: 'Moravskoslezský kraj',
    chartStyles: {
      left: '89.22%',
      top: '52.64%',
    },
  },
};

export const regions: Regions = {
  'cz01': {
    name: 'Praha',
    districts: ['cz010'],
    chartStyles: {
      left: '35.23%',
      top: '41.16%',
      width: '9%',
    },
  },
  'cz02': {
    name: 'Střední Čechy',
    districts: ['cz020'],
    chartStyles: {
      left: '42.24%',
      top: '46.32%',
    },
  },
  'cz03': {
    name: 'Jihozápad',
    districts: ['cz031', 'cz032'],
    chartStyles: {
      left: '22.545%',
      top: '68.735%',
    },
  },
  'cz04': {
    name: 'Severozápad',
    districts: ['cz041', 'cz042'],
    chartStyles: {
      left: '17.57%',
      top: '31.355%',
    },
  },
  'cz05': {
    name: 'Severovýchod',
    districts: ['cz051', 'cz052', 'cz053'],
    chartStyles: {
      left: '53.73%',
      top: '29.67%',
    },
  },
  'cz06': {
    name: 'Jihovýchod',
    districts: ['cz063', 'cz064'],
    chartStyles: {
      left: '60.2%',
      top: '70.51%',
    }
  },
  'cz07': {
    name: 'Střední Morava',
    districts: ['cz071', 'cz072'],
    chartStyles: {
      left: '79.83%',
      top: '65.09%',
    }
  },
  'cz08': {
    name: 'Moravskoslezsko',
    districts: ['cz080'],
    chartStyles: {
      left: '89.22%',
      top: '52.64%',
    },
  },
};

export const parties: Parties = {
  'ods': {
    name: 'ODS',
    color: '#1D4F9C',
  },
  'rn-vu': {
    name: 'ŘN - VU',
    color: '',
  },
  'cesta': {
    name: 'CESTA',
    color: '',
  },
  'cssd': {
    name: 'ČSSD',
    color: '#DB6128',
  },
  'pb': {
    name: 'PB',
    color: '',
  },
  'rc': {
    name: 'RČ',
    color: '',
  },
  'stan': {
    name: 'STAN',
    color: '#8DBF96',
  },
  'kscm': {
    name: 'KSČM',
    color: '#CE2C28',
  },
  'zeleni': {
    name: 'Zelení',
    color: '',
  },
  'rozumni': {
    name: 'Rozumní',
    color: '',
  },
  'spdv': {
    name: 'SPDV',
    color: '',
  },
  'svobodni': {
    name: 'Svobodní',
    color: '',
  },
  'bpi': {
    name: 'BPI',
    color: '',
  },
  'oda': {
    name: 'ODA',
    color: '',
  },
  'pirati': {
    name: 'Piráti',
    color: '#000000',
  },
  'obcane-2011': {
    name: 'OBČANÉ 2011',
    color: '',
  },
  'unie-havel': {
    name: 'Unie H.A.V.E.L.',
    color: '',
  },
  'cnf': {
    name: 'ČNF',
    color: '',
  },
  'referendum-o-eu': {
    name: 'Referendum o EU',
    color: '',
  },
  'top-09': {
    name: 'TOP 09',
    color: '#643760',
  },
  'ano': {
    name: 'ANO',
    color: '#26285D',
  },
  'dv-2016': {
    name: 'DV 2016',
    color: '',
  },
  'sprrsc-m-sladka': {
    name: 'SPRRSČ M.Sládka',
    color: '',
  },
  'kdu-csl': {
    name: 'KDU-ČSL',
    color: '#F9D749',
  },
  'csns': {
    name: 'ČSNS',
    color: '',
  },
  'real': {
    name: 'REAL',
    color: '',
  },
  'sportovci': {
    name: 'SPORTOVCI',
    color: '',
  },
  'dsss': {
    name: 'DSSS',
    color: '',
  },
  'spd': {
    name: 'SPD',
    color: '#3274B7',
  },
  'spo': {
    name: 'SPO',
    color: '',
  },
  'nas': {
    name: 'NáS',
    color: '',
  },
};

export const mandatesPerDistrict: MandatesPerUnit = {
  'cz010': 24,
  'cz020': 26,
  'cz031': 13,
  'cz032': 11,
  'cz041': 5,
  'cz042': 13,
  'cz051': 8,
  'cz052': 11,
  'cz053': 10,
  'cz063': 10,
  'cz064': 23,
  'cz071': 12,
  'cz072': 12,
  'cz080': 22,
};

for (const [region, { districts }] of Object.entries(regions)) {
  mandatesPerDistrict[region] = districts
    .map(d => mandatesPerDistrict[d])
    .reduce((sum, m) => sum + m, 0);
}
