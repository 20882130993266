import { AbstractQuota } from './abstractQuota';
import { Dataset } from '../types';

export class HagenbachBischoff extends AbstractQuota {
  getName(): string {
    return 'Hagenbach-Bischoffova kvóta';
  }

  getDescription(): string {
    return `
      Tato metoda, obdobně jako další kvótové metody, pracuje ve třech krocích. Nejprve se z počtu
      odevzdaných platných hlasů (V) a počtu mandátů v daném obvodě (S) vypočte tzv. "mandátové číslo".
      V případě Hagenbach-Bischoffovy kvóty se toto číslo získá vydělením počtu platných hlasů počtem
      mandátů povýšeným o jedničku (=V / (S + 1)). V druhém kroku se vypočte základní počet mandátů
      pro každou stranu prostým vydělením počtu hlasů, které strana získala, mandátovým číslem;
      pokud celkový počet takto přepočtených mandátů nedosahuje celkového počtu mandátů, které měly
      v daném obvodě být rozděleny, přidělí se stranám postupně po jednom mandátu v pořadí sestupně
      podle zbytku po dělení v druhém kroce, dokud nejsou zbývající mandáty rozděleny.
    `;
  }

  getUrl(): string {
    return 'https://cs.wikipedia.org/wiki/Hagenbach-Bischoffova_kvóta';
  }

  protected getQuota(dataset: Dataset, mandates: number): number {
    return dataset.totalVotesAboveQuorum / (mandates + 1);
  }
}
