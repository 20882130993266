import {
  Dataset,
  Datasets,
  DistributionMethod,
  MandatesPerUnitAndParty,
  MandatesPerParty,
  Results,
  VotesPerUnitAndParty,
  VotesPerParty,
} from './types';
import { mandatesPerDistrict, regions } from './maps';

export function objectMap<O extends Record<any, any>, F extends (
  value: O[keyof O],
  key: keyof O,
) => any>(
  o: O,
  f: F,
): Record<keyof O, ReturnType<F>> {
  const r: any = {};

  for (const [k, v] of Object.entries(o)) {
    r[k] = f(v, k);
  }

  return r;
}

export function objectFilter<O extends Record<any, any>, F extends (
  value: O[keyof O],
  key: keyof O,
) => boolean>(
  o: O,
  f: F,
): O {
  const r: any = {};

  for (const [k, v] of Object.entries(o)) {
    if (f(v, k)) {
      r[k] = v;
    }
  }

  return r;
}

export function objectFilterMap<O extends Record<any, any>, F extends (
  value: O[keyof O],
  key: keyof O,
  skip: () => O[keyof O],
) => any>(
  o: O,
  f: F,
): Record<keyof O, ReturnType<F>> {
  const r: any = {};
  let skipped: boolean;
  const skip = (): any => {
    skipped = true;
  };

  for (const [k, v] of Object.entries(o)) {
    skipped = false;

    const v2 = f(v, k, skip);

    if (!skipped) {
      r[k] = v2;
    }
  }

  return r;
}

export function maxKey(o: Record<any, number>): keyof typeof o {
  return Object.entries(o)
    .sort((a, b) => b[1] - a[1])
    .shift()!
    .shift()!;
}

export function expandDataset(votesPerDistrictAndParty: VotesPerUnitAndParty): Datasets {
  const global: Dataset = createDataset();
  const perRegion: Record<string, Dataset> = {};
  const perDistrict: Record<string, Dataset> = {};

  for (const [rid, {districts}] of Object.entries(regions)) {
    const region = perRegion[rid] = createDataset();

    for (const did of districts) {
      const district = perDistrict[did] = createDataset();

      if (did in votesPerDistrictAndParty) {
        for (const [party, votes] of Object.entries(votesPerDistrictAndParty[did])) {
          district.totalVotes += votes;
          region.totalVotes += votes;
          global.totalVotes += votes;
          district.votesPerParty[party] = votes;
          region.votesPerParty[party] = (region.votesPerParty[party] || 0) + votes;
          global.votesPerParty[party] = (global.votesPerParty[party] || 0) + votes;
        }
      }
    }
  }

  global.partiesAboveQuorum = Object.entries(global.votesPerParty)
    .filter(([, votes]) => votes / global.totalVotes >= 0.05)
    .map(([party]) => party);

  global.totalVotesAboveQuorum = Object.entries(global.votesPerParty)
    .filter(([party]) => global.partiesAboveQuorum.includes(party))
    .reduce((sum, [, votes]) => sum + votes, 0);

  for (const node of Object.values(perRegion).concat(Object.values(perDistrict))) {
    node.partiesAboveQuorum = Object.keys(node.votesPerParty)
      .filter(party => global.partiesAboveQuorum.includes(party));

    node.totalVotesAboveQuorum = Object.entries(node.votesPerParty)
      .filter(([party]) => node.partiesAboveQuorum.includes(party))
      .reduce((sum, [, votes]) => sum + votes, 0);
  }

  return {
    perDistrict,
    perRegion,
    global,
  };
}

function createDataset(): Dataset {
  return {
    totalVotes: 0,
    votesPerParty: {},
    partiesAboveQuorum: [],
    totalVotesAboveQuorum: 0,
  };
}

export function computeResults(
  datasets: Datasets,
  method: DistributionMethod,
  level: number,
): Results {
  let mandatesPerUnitAndParty: MandatesPerUnitAndParty | undefined = undefined;
  let mandatesPerParty: MandatesPerParty;

  if (level === 0) {
    mandatesPerParty = method.getMandateDistribution(datasets.global, 200);
  } else {
    const unitDatasets = level === 1 ? datasets.perRegion : datasets.perDistrict;
    mandatesPerUnitAndParty = computeResultForDatasets(unitDatasets, method);
    mandatesPerParty = getMandatesPerParty(mandatesPerUnitAndParty);
  }

  const votesPerMandatePerParty = getVotesPerMandatePerParty(
    mandatesPerParty,
    datasets.global.votesPerParty,
  );

  return {
    ...datasets,
    mandatesPerUnitAndParty,
    mandatesPerParty,
    votesPerMandatePerParty,
  };
}

function computeResultForDatasets(
  datasets: Record<string, Dataset>,
  method: DistributionMethod,
): MandatesPerUnitAndParty {
  const mandatesPerDistrictAndParty: MandatesPerUnitAndParty = {};

  for (const [district, dataset] of Object.entries(datasets)) {
    mandatesPerDistrictAndParty[district] = method.getMandateDistribution(
      dataset,
      mandatesPerDistrict[district],
    );
  }

  return mandatesPerDistrictAndParty;
}

function getMandatesPerParty(mandatesPerDistrictAndParty: MandatesPerUnitAndParty): MandatesPerParty {
  const mandatesPerParty: MandatesPerParty = {};

  for (const districtMandates of Object.values(mandatesPerDistrictAndParty)) {
    for (const [party, mandates] of Object.entries(districtMandates)) {
      party in mandatesPerParty || (mandatesPerParty[party] = 0);
      mandatesPerParty[party] += mandates;
    }
  }

  return mandatesPerParty;
}

function getVotesPerMandatePerParty(
  mandatesPerParty: MandatesPerParty,
  votesPerParty: VotesPerParty,
): VotesPerParty {
  return objectMap(
    mandatesPerParty,
    (mandates, party) => Math.round(votesPerParty[party] / mandates),
  );
}
