import { Dataset, DistributionMethod, MandatesPerParty } from '../types';

export abstract class AbstractQuota implements DistributionMethod {
  abstract getName(): string;
  abstract getDescription(): string;

  protected abstract getQuota(dataset: Dataset, mandates: number): number;

  getMandateDistribution(dataset: Dataset, mandates: number): MandatesPerParty {
    const mandatesPerParty: MandatesPerParty = {};
    const quota = this.getQuota(dataset, mandates);
    const remainders: MandatesPerParty = {};
    let totalMandates = 0;

    for (const party of dataset.partiesAboveQuorum) {
      const q = dataset.votesPerParty[party] / quota;
      mandatesPerParty[party] = Math.floor(q);
      remainders[party] = q - mandatesPerParty[party];
      totalMandates += mandatesPerParty[party];
    }

    const partiesByRemainder = Object.entries(remainders)
      .sort((a, b) => b[1] - a[1])
      .map(([party]) => party);

    for (const party of partiesByRemainder.slice(0, mandates - totalMandates)) {
      ++mandatesPerParty[party];
    }

    return mandatesPerParty;
  }
}
