import React, { useEffect } from 'react';
import { useDerivedState } from './utils/derivedState';

export type AsyncWorkingProps = {
  abort?: () => void;
  children?: never;
}

export const AsyncWorking: React.FunctionComponent<AsyncWorkingProps> = ({
  abort,
}) => (
  <p className="async-working">
    {'načítám... '}
    {abort && <button type="button" className="btn btn-link" onClick={abort}>zrušit</button>}
  </p>
);

export type AsyncErrorProps = {
  aborted: boolean,
  error?: any;
  children?: never;
};

export const AsyncError: React.FunctionComponent<AsyncErrorProps> = ({
  error,
  aborted,
}) => (
  <p className="async-error">
    {aborted ? 'Zrušeno.' : `Chyba: ${error}`}
  </p>
);

export type AsyncComponentPromiseFactory<T> = (signal?: AbortSignal) => Promise<T>;
export type AsyncComponentResultRenderer<T> = (result: T, reset: () => void) => (React.ReactElement | null);

export type AsyncComponentProps<T> = {
  factory: AsyncComponentPromiseFactory<T>;
  abortable?: boolean;
  working?: React.ComponentType<AsyncWorkingProps>;
  error?: React.ComponentType<AsyncErrorProps>;
  children: AsyncComponentResultRenderer<T>;
};

type PendingState = { status: 'pending', abort?: () => void };
type AbortedState = { status: 'aborted' };
type ErrorState = { status: 'error', error: any };
type SuccessState<T> = { status: 'ok', result: T };
type AsyncState<T> = PendingState | AbortedState | ErrorState | SuccessState<T>;

type StateFactory<T> = (factory: AsyncComponentPromiseFactory<T>, abortable: boolean) => AsyncState<T> | undefined;

function deriveInitialState<T>(factory: AsyncComponentPromiseFactory<T>, abortable: boolean): AsyncState<T> | undefined {
  return undefined;
}

export function AsyncComponent<T>({
  factory,
  abortable = false,
  working: WorkingComponent = AsyncWorking,
  error: ErrorComponent = AsyncError,
  children,
}: AsyncComponentProps<T>): React.ReactElement | null {
  const [state, setState] = useDerivedState<StateFactory<T>>(
    deriveInitialState,
    [factory, abortable],
  );

  const init = !state;

  useEffect(() => {
    if (!init) {
      return;
    }

    const controller = abortable ? new AbortController() : undefined;

    factory(controller && controller.signal)
      .then(result => setState({ status: 'ok', result }))
      .catch(error => setState(error.name === 'AbortError' ? { status: 'aborted' } : { status: 'error', error }));

    setState({
      status: 'pending',
      abort: controller && controller.abort.bind(controller),
    });
  }, [setState, init, factory, abortable]);

  if (!state) {
    return <WorkingComponent />;
  }

  switch (state.status) {
    case 'pending': return <WorkingComponent abort={state.abort} />;
    case 'aborted': return <ErrorComponent aborted />;
    case 'error': return <ErrorComponent aborted={false} error={state.error} />;
    case 'ok': return children(state.result, () => setState(undefined));
  }
}
