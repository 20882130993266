import React, { createContext, useContext, useState } from 'react';
import { methods } from './model';

export type Settings = {
  datasetUrl: string;
  method: string;
  level: number;
};

type SettingsCtx = {
  settings: Settings;
  setSettings: React.Dispatch<React.SetStateAction<Settings>>;
}

const SettingsContext = createContext<SettingsCtx>(undefined as any);

export function useSettings() : Settings {
  const { settings } = useContext(SettingsContext);
  return settings;
}

const defaults: Settings = {
  datasetUrl: '/datasets/ps-2017.json',
  method: 'dhondt',
  level: 2,
};

export const SettingsProvider: React.FC = ({ children }) => {
  const [settings, setSettings] = useState<Settings>(defaults);

  return (
    <SettingsContext.Provider value={{ settings, setSettings }}>
      {children}
    </SettingsContext.Provider>
  );
}

export const SelectMethod: React.FC = () => {
  const { settings, setSettings } = useContext(SettingsContext);

  return (
    <select value={settings.method} onChange={evt => setSettings((s) => ({ ...s, method: evt.target.value }))}>
      {Object.entries(methods).map(([method, impl]) => (
        <option key={method} value={method}>{impl.getName()}</option>
      ))}
    </select>
  );
}

export const SelectDataset: React.FC = () => {
  const { settings, setSettings } = useContext(SettingsContext);

  return (
    <select value={settings.datasetUrl} onChange={evt => setSettings((s) => ({ ...s, datasetUrl: evt.target.value }))}>
      <option value="/datasets/ps-2017.json">Volby do PS 2017</option>
    </select>
  );
}

export const SelectLevel: React.FC = () => {
  const { settings, setSettings } = useContext(SettingsContext);

  return (
    <select value={settings.level} onChange={evt => setSettings((s) => ({ ...s, level: +evt.target.value }))}>
      <option value="0">NUTS 1 (celá ČR)</option>
      <option value="1">NUTS 2 (regiony)</option>
      <option value="2">NUTS 3 (kraje)</option>
    </select>
  );
}
