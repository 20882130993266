import React from 'react';
import { useResults } from './ResultsProvider';
import { parties } from './model';

import './Stats.css';
import { strcmp } from './utils';

export const Stats: React.FC = () => {
  const results = useResults();

  if (!results) {
    return null;
  }

  return (
    <>
      <h3>Celkové výsledky:</h3>
      <table className="stats">
        <thead>
          <tr>
            <th>Strana</th>
            <th>Mandátů</th>
            <th>Hlasů / mandát</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(results.mandatesPerParty).sort((a, b) => strcmp(a[0], b[0])).map(([party, mandates]) => (
            <tr key={party}>
              <th>{parties[party].name}</th>
              <td>{mandates.toString().replace(/(\.\d\d)\d+/, '$1')}</td>
              <td>{results?.votesPerMandatePerParty[party]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
};
