import { AbstractDivisor } from './abstractDivisor';

export class SainteLague extends AbstractDivisor {
  getName(): string {
    return 'Webster/Sainte-Laguë';
  }

  getDescription(): string {
    return `
      Obdobně jako d'Hondtova metoda i Sainte-Laguēova metoda je metodou tzv. "volebního dělitele".
      Mechanika vícekolového vyhodnocení je stejná, liší se pouze dělitel, který se v každém kole
      použije: u Sainte-Laguēovy metody je dělitelem dvojnásobek počtu mandátů, které strana získala
      v předchozích kolech, zvýšený o jedničku (=počet hlasů / (2 × mezipočet mandátů + 1)). Jinými
      slovy, u d'Hondtovy metody budou děliteli postupně čísla 1, 2, 3, 4, ..., zatímco v případě
      Sainte-Laguēovy metody to budou čísla 1, 3, 5, 7, ...
    `;
  }

  getUrl(): string {
    return 'https://cs.wikipedia.org/wiki/Sainte-Laguëova_metoda';
  }

  protected getDivisor(round: number, mandates: number): number {
    return 2 * mandates + 1;
  }
}
